.leader-wrapper {
  display: flex;
  margin-top: 100px;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;

  .leader-photo {
    width: 300px;
  }

  .leader-text-wrapper {
    width: 500px;
    display: flex;
    flex-direction: column;
    color: #222;
    text-align: left;

    .leader-text-title {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 4.5px;
    }

    .leader-text-name {
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: 0.9px;
      margin: 20px 0;
    }

    .leader-text-about {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      color: #888;
    }

    .leader-text-bye {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: #888;
    }
  }

  @media (max-width: 1000px) {
    .leader-text-wrapper {
      width: 400px;
    }
  }

  @media (max-width: 850px) {
    .leader-text-wrapper {
      width: 350px;
    }
  }

  @media (max-width: 750px) {
    .leader-photo {
      width: 250px;
    }

    .leader-text-wrapper {
      .leader-text-name {
        font-size: 30px;
        margin: 10px 0;
      }

      .leader-text-about {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: center;

    .leader-photo {
      width: 500px;
    }

    .leader-text-wrapper {
      width: 500px;

      .leader-text-title {
        font-size: 20px;
      }

      .leader-text-name {
        font-size: 40px;
        margin: 10px 0;
      }

      .leader-text-about {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 550px) {
    .leader-photo {
      width: 400px;
    }

    .leader-text-wrapper {
      width: 400px;
    }
  }

  @media (max-width: 400px) {
    .leader-photo {
      width: 300px;
    }

    .leader-text-wrapper {
      width: 300px;
    }
  }

  @media (max-width: 300px) {
    .leader-photo {
      width: 230px;
    }

    .leader-text-wrapper {
      width: 230px;
    }
  }
}

.projects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;

  .projects-title {
    font-size: 33px;
    color: rgb(18, 18, 18);
    text-transform: uppercase;
    font-weight: 700;
  }

  .projects-cards {
    margin-top: 80px;
    margin-bottom: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

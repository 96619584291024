.description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .description-title {
    margin-top: 50px;
    font-size: 14px;
    letter-spacing: 4.5px;
    max-width: 90%;
  }

  .description-name {
    margin-top: 18px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.9px;
    color: rgb(17, 17, 17);
    max-width: 90%;
  }

  .description-text {
    margin-top: 40px;
    margin-bottom: 60px;
    max-width: 770px;
    line-height: 32px;
    font-size: 16px;
    max-width: 90%;
  }

  .description-world-wrapper {
    margin-top: 40px;
    width: 100%;
    padding: 50px 0 40px 0;
    background-color: black;
    color: #eee;

    display: flex;
    flex-direction: column;
    align-items: center;

    .description-world-header {
      font-size: 40px;
      letter-spacing: 0.9px;
      font-weight: 700;
      max-width: 90%;
    }

    .description-world-text {
      max-width: 770px;
      margin-top: 26px;
      line-height: 32px;
      max-width: 90%;
    }
  }
}

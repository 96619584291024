.benefit-card-wrapper {
  width: 300px;
  min-height: 300px;
  padding: 35px;
  transition: filter 0.3s ease-in;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .benefit-card-icon {
    width: 70px;
    text-align: end;
  }

  .benefit-card-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    padding: 30px 0;
    text-align: end;
  }

  .benefit-card-text {
    font-size: 15px;
    line-height: 24px;
    color: #777;
    text-align: end;
  }

  &:hover {
    filter: invert(100%);
    transition: filter 0.3s ease-in;
  }

  @media (max-width: 1000px) {
    width: 250px;
  }

  @media (max-width: 850px) {
    width: 200px;
  }

  @media (max-width: 720px) {
    width: 170px;
  }

  @media (max-width: 645px) {
    width: 350px;
  }
}

.header-container {
  height: 112px;
  padding: 14px;
  background-color: #9c836f;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .app-logo {
    height: 100%;
    pointer-events: none;
  }

  span {
    text-transform: uppercase;
    margin: 0 20px;
    color: white;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 850px) {
    font-size: 12px;
    height: 80px;
  }

  @media (max-width: 700px) {
    font-size: 11px;
    height: 50px;

    span {
      margin: 0 8px;
    }
  }

  @media (max-width: 500px) {
    padding: 10px;

    font-size: 9px;
    height: 40px;

    span {
      margin: 0 4px;
    }
  }

  @media (max-width: 500px) {
    padding: 8px;

    font-size: 9px;
    height: 30px;

    span {
      margin: 0 1px;
      text-transform: capitalize;
    }
  }
}

.project-card-wrapper {
  width: 350px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .project-card-year {
    font-size: 12px;
    line-height: 12px;
    color: #555;
    position: relative;
    text-align: left;
    width: min-content;

    &::before {
      position: absolute;
      width: 1px;
      height: 60px;
      background-color: black;
      content: "";
      top: 20px;
      left: 50%;
    }
  }

  .project-card-year-clear {
    color: #5550;
    position: relative;
    width: min-content;

    &::before {
      position: absolute;
      width: 1px;
      height: 72px;
      background-color: #0000;
      content: "";
      top: 20px;
      left: 10px;
    }
  }

  .project-card-image {
    margin-top: 30px;
    width: 100%;
    cursor: pointer;
  }

  .project-card-title {
    margin-top: 15px;
    height: 60px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
  }

  @media (max-width: 1500px) {
    width: 500px;
    min-height: 500px;
  }

  @media (max-width: 1100px) {
    width: 350px;
    min-height: 350px;
  }

  @media (max-width: 800px) {
    width: 500px;
    min-height: 500px;
  }

  @media (max-width: 600px) {
    width: 400px;
    min-height: 400px;
  }

  @media (max-width: 500px) {
    width: 300px;
    min-height: 300px;
  }

  @media (max-width: 300px) {
    width: 230px;
    min-height: 230px;
  }

  .gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
  }

  .gallery-content {
    position: relative;
    z-index: 1001;
    width: 90%;
    height: 90vh;
    max-width: 1200px;
  }

  .gallery-close {
    position: absolute;
    top: -40px;
    right: -40px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1002;
    
    &:hover {
      color: #ccc;
    }
  }

  // Стили для react-image-gallery
  :global {
    .image-gallery {
      height: 100%;
    }

    .image-gallery-content {
      height: 100%;
    }

    .image-gallery-slide-wrapper {
      height: calc(100% - 100px);
    }

    .image-gallery-slide {
      height: 100%;
      
      img {
        object-fit: contain;
        max-height: 100%;
      }
    }

    .image-gallery-thumbnails-wrapper {
      background: rgba(0, 0, 0, 0.3);
      padding: 10px 0;
    }
  }
}

.contacts-wrapper {
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .contacts-fixed-button-wrapper{
    position: fixed;
    top: 50%;
    right: 10px;
    background-color: white;
    border-radius: 3333px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .contacts-fixed-button-image{
      margin-top: 3px;
      width: 35px;
      height: 35px;
      
    }
  }

  .contacts-content{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contacts-title {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 0.9;
      text-align: center;
      margin-bottom: 30px;
    }

    .contacts-done{
      margin-top: 40px;
      font-size: 30px;
      text-align: center;
      color: rgba($color: #000000, $alpha: .7)

    }

    .contacts-form{
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;

      .contacts-form-input{
        margin-bottom: 30px;
        padding: 6px;
        width: 100%;
        height: 40px;
        font-size: 25px;
        border-color: #000000;
        border-width: 1px;

        @media (max-width: 400px) {
          width: 90%;
        }
      }

    }

    .contacts-body {
      margin-top: 30px;
      font-weight: 600;
      color: #444;
      width: fit-content;

      .contacts-body-item {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .contacts-body-item-icon {
          width: 25px;
          margin-right: 10px;
        }

        a {
          margin-right: 6px;
          color: #444;

          &:hover {
            color: #111;
          }
        }
      }
    }
  }
}

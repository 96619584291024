.feedbacks-wrapper {
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .feedbacks-content{
    width: fit-content;

    .feedbacks-title {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 0.9;
      text-align: center;
      
    }

    .feedbacks-body {
      margin-top: 40px;
      font-weight: 600;
      color: #444;

      .feedbacks-body-item {
        margin-top: 30px;
        display: flex;
        width: 600px;

        @media (max-width: 700px) {
          width: 500px;
        }

        @media (max-width: 600px) {
          width: 400px;
        }

        @media (max-width: 500px) {
          width: 350px;
        }

        @media (max-width: 400px) {
          width: 300px;
        }

        @media (max-width: 350px) {
          width: 250px;
        }
      }

      .feedbacks-card-name{
        text-align: left;
      }

      .feedbacks-card-text{
        text-align: left;
        margin-left: 20px;
        margin-top: -6px;
        font-weight: 400;
      }
    }
  }
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 160px;
  margin-bottom: 130px;

  .services-title {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.9px;
  }

  .services-cards {
    display: flex;
    gap: 20px;
    padding-bottom: 40px;

    max-width: 80%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

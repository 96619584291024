.button-wrapper {
  position: relative;
  color: var(--main-color);
  background-color: var(--main-bg-color);
  border: 1px solid var(--main-border-color);
  width: min-content;

  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;
  user-select: none;

  padding: 10px 46px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  cursor: pointer;

  &::before {
    position: absolute;
    height: 1px;
    width: 30px;
    left: -15px;
    top: 50%;
    background-color: var(--main-border-color);
    content: "";
    transition: background-color 0.3s ease-in;
  }

  &::after {
    position: absolute;
    height: 1px;
    width: 30px;
    right: -15px;
    top: 50%;
    background-color: var(--main-border-color);
    content: "";
    transition: background-color 0.3s ease-in;
  }

  &:hover {
    color: var(--hover-color);
    background-color: var(--hover-bg-color);
    transition: background-color 0.3s ease-in, color 0.3s ease-in;

    &::before,
    &::after {
      background-color: var(--hover-border-color);
      transition: background-color 0.3s ease-in;
    }
  }

  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 20px;

    padding: 5px 23px;
  }
}

.intro-jpg-container {
  position: relative;
  background-color: #000;
  overflow: hidden;

  .intro-jpg {
    width: 100%;
    opacity: 0.65;
    margin-bottom: -10px;
  }

  .intro-text {
    position: absolute;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    .intro-text-container {
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 90%;

      .intro-text-name {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 4.5px;
      }

      .intro-text-title {
        font-size: 54px;
        margin-top: 10px;
        font-weight: 200;
        letter-spacing: 4.5px;
      }

      .intro-text-subtitle {
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 35px;
        font-weight: 400;
        opacity: 0.5;
        text-transform: uppercase;
      }

      @media (max-width: 1000px) {
        padding-bottom: 50px;
      }

      @media (max-width: 800px) {
        padding-bottom: 0px;

        .intro-text-name {
          font-size: 11px;
        }

        .intro-text-title {
          font-size: 40px;
          margin-top: 0px;
        }

        .intro-text-subtitle {
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      @media (max-width: 500px) {
        .intro-text-name {
          font-size: 11px;
        }

        .intro-text-title {
          font-size: 30px;
          margin-top: 0px;
        }

        .intro-text-subtitle {
          font-size: 10px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      @media (max-width: 300px) {
        .intro-text-name {
          font-size: 9px;
        }

        .intro-text-title {
          font-size: 25px;
          margin-top: 0px;
        }

        .intro-text-subtitle {
          font-size: 8px;
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
